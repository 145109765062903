// import React from 'react'
// import Post from './post/Post'
// import useStyles from './styles'
// import { useSelector } from 'react-redux'
// import { Grid, CircularProgress } from '@material-ui/core';

// const Posts = ({setCurrentId}) => {

//   const posts = useSelector ((state ) => state.Posts);
//   const classes = useStyles();

//   console.log(posts)
//   return (
//   !posts.length? <CircularProgress/> : (
//     <Grid className={classes.container} container alignItems='stretch' spacing={3} >

//       {posts.map((post)=>(
//         <Grid key={post._id} item xs={12} sm={6} md={6} >
//          <Post post={post} setCurrentId={setCurrentId}/>
//          </Grid>
//       ))}
//     </Grid>
//   )
//   )
// }
// export default Posts



import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core'; 
import { useSelector } from 'react-redux';

import Post from './post/Post';
import useStyles from './styles';

const Posts = ({ setCurrentId }) => {

  // const Posts = useSelector((state) => state.posts);

  // const {posts , isLoading} = useSelector ((state ) => state.Posts);
  const {posts , isLoading} = useSelector ((state ) => state.posts);


  const classes = useStyles();

  if (!posts.length&& !isLoading) return ' No posts found'

  return (
   isLoading? <CircularProgress /> : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3}>
        {posts.map((post) => (
          <Grid key={post._id} item xs={12} sm={12} md={6} lg={3}>
            <Post post={post} setCurrentId={setCurrentId} />
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default Posts;