import "./App.css";

import React from "react";

import { Container } from "@material-ui/core";
import PostDetails from "./comps/PostDetails/PostDetails";

import { BrowserRouter, Switch, Route,Redirect } from "react-router-dom";

import NavBar from "./comps/NavBar/NavBar";
import Home from "./comps/Home/Home";
import Auth from "./comps/Auth/Auth";

function App() {

  const user = JSON.parse(localStorage.getItem('profile'));
  return (
    <Container maxWidth="xl">
      <BrowserRouter>
        <Container maxWidth="lg">
          <NavBar />
          <Switch>
            <Route path="/" exact component={()=><Redirect to="/posts"  />} />
            <Route path="/posts" exact component={Home} />
            <Route path="/posts/Search" exact component={Home} />
            <Route path="/posts/:id" exact component={PostDetails} />
            <Route path="/auth" exact component={()=>(!user ? <Auth/> : <Redirect to="/posts" /> )} />
          </Switch>
        </Container>
      </BrowserRouter>
    </Container>
  );
}

export default App;
