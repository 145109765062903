import React, { useEffect, useState } from 'react'

import {AppBar, Typography,Toolbar,Button, Avatar} from '@material-ui/core'
import memoriesLogo from '../../images/memories-Logo.png'
import memoriesText from '../../images/memories-Text.png'


import useStyles from './styles'

import { useDispatch  } from 'react-redux'
import { useHistory,useLocation } from 'react-router-dom'

import {Link} from 'react-router-dom'

import decode from 'jwt-decode'

const NavBar = () => {

    const classes = useStyles();
    
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    // console.log(user)

    const Logout =()=>{

      dispatch({type:"LOGOUT"})
      history.push('/')

      setUser(null);
         
    };

    useEffect(() => {
      const token= user?.token;

      if (token) {
        const decodedToken = decode(token);
        if (decodedToken.exp*1000 < new Date().getTime()) Logout();
      }
      setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

  return (
    <AppBar className={classes.appBar}  position="static" color="inherit">

    <Link to="/" className={classes.brandContainer} >
    {/* <Typography component={Link} to='/'  className={classes.heading} variant="h2" align="center">Memories</Typography> */}
    <img src={memoriesText} alt='icon' height="45px"/>
      <img  className={classes.image} src={memoriesLogo} alt="icon" height="40px" />
    </Link>

    <Toolbar className={classes.toolbar} >
        {user ? (<div className={classes.profile} >
          <Avatar className={classes.purple} alt={user.result.name} src= {user.result.imageUrl} >{user.result.name.charAt(0)}</Avatar>
          <Typography className={classes.userName} variant='h6'>{user.result.name}</Typography>
          <Button variant='contained' className={classes.logout} color='secondary' onClick={Logout} > LogOut</Button>
        </div>): (<div>
                <Button component={Link}  to='/auth' variant='contained' color='primary' >Sing In</Button>
        </div>)}
    </Toolbar>
      

    </AppBar>
  )
}

export default NavBar