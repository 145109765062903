export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_POST = 'FETCH_POST';

export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';


export const LIKE = 'LIKE';

export const START_LOADING ='START_LOADING'
export const END_LOADING ='END_LOADING'


export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const COMMENT = 'COMMENT';